import React from "react";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@shared/ui/atoms/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import { useHistory, useLocation } from "react-router-dom";
import Stack from "@mui/material/Stack";
import ButtonForm from "@shared/ui/molecules/ButtonForm";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import Warehouse from "@assets/images/warehouse.svg";
import Panel from "@shared/ui/molecules/Panel/Panel";
import TransportOrderWizardTemplate from "@shared/ui/templates/TransportOrderWizardTemplate";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import TextArea from "@shared/ui/atoms/TextArea";
import { Trans, useTranslation } from "react-i18next";
import validationSchema from "./Details.validationSchema";
import scheduleValidationSchema from "../schedule/Schedule.validationSchema";

const Details: React.FC<any> = () => {
  const location = useLocation();
  const routeParams = { ...location.state };
  const currencyIsoCodes = ["PLN", "EUR", "GBP"];
  const history = useHistory();
  const { i18n } = useTranslation();

  const handleSubmit = (values: any) => {
    history.replace(routes.root.transportOrders.new.summary(), values);
  };

  const formik = useFormik({
    initialValues: {
      currency: "PLN",
      preferences: {
        company: true,
        privatePerson: true,
        invoice: false
      },
      extraInformation: "",
      ...routeParams
    },
    validationSchema: validationSchema(i18n),
    onSubmit: handleSubmit
  });

  const companyHandleChange = (event: any) => {
    formik.handleChange(event);

    if (event.target.checked === false) {
      formik.setFieldValue("preferences.invoice", false);

      if (formik.values.preferences.privatePerson === false) {
        formik.setFieldValue("preferences.privatePerson", true);
      }
    }
  };

  const privatePersonHandleChange = (e: any) => {
    formik.handleChange(e);

    if (
      formik.values.preferences.company === false &&
      e.target.checked === false
    ) {
      formik.setFieldValue("preferences.company", true);
    }
  };

  const comeBack = () => {
    history.replace(routes.root.transportOrders.new.schedule(), {
      ...routeParams
    });
  };

  if (!scheduleValidationSchema(i18n).isValidSync({ ...routeParams })) {
    history.replace(routes.root.transportOrders.new.category());

    return null;
  }

  return (
    <TransportOrderWizardTemplate
      step={2}
      seoProps={{
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Panel sx={{ backgroundColor: "#ffffff" }}>
              <PanelHeader>Dodatkowe informacje</PanelHeader>

              <Stack spacing={2}>
                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        <Trans i18nKey="labels.currency" />
                      </strong>
                    </Typography>
                    <FormControl
                      fullWidth
                      size="small"
                      error={
                        formik.touched.currency &&
                        Boolean(formik.errors.currency)
                      }
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        name="currency"
                        onChange={formik.handleChange}
                        value={formik.values.currency}
                        disabled
                      >
                        {currencyIsoCodes.map((name) => (
                          <MenuItem value={name} key={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {formik.touched.currency && formik.errors.currency}
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                </Box>

                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Preferencje ofert</strong>
                    </Typography>
                    <FormGroup>
                      <Stack spacing={1}>
                        <Checkbox
                          label="Osoba prywatna"
                          name="preferences.privatePerson"
                          value={formik.values.preferences.privatePerson}
                          checked={formik.values.preferences.privatePerson}
                          onChange={(e: any) => privatePersonHandleChange(e)}
                        />
                        <Checkbox
                          label="Frima"
                          name="preferences.company"
                          value={formik.values.preferences.company}
                          checked={formik.values.preferences.company}
                          onChange={(e: any) => companyHandleChange(e)}
                        />
                        {formik.values.preferences.company === true && (
                          <Box>
                            <Box sx={{ marginLeft: "28px" }}>
                              <Checkbox
                                label="Faktura VAT"
                                name="preferences.invoice"
                                value={formik.values.preferences.invoice}
                                checked={formik.values.preferences.invoice}
                                onChange={formik.handleChange}
                              />
                            </Box>
                          </Box>
                        )}
                      </Stack>
                    </FormGroup>
                  </Stack>
                </Box>

                <Box>
                  <Stack spacing={1}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Dodatkowe informacje</strong>
                    </Typography>
                    <TextArea
                      id="outlined-multiline-static"
                      name="extraInformation"
                      onChange={formik.handleChange}
                      value={formik.values.extraInformation}
                      placeholder={i18n.t("labels.additional_information")}
                      error={
                        formik.touched.extraInformation &&
                        Boolean(formik.errors.extraInformation)
                      }
                      helperText={
                        formik.touched.extraInformation &&
                        formik.errors.extraInformation
                      }
                    />
                  </Stack>
                </Box>
              </Stack>
            </Panel>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack direction="column" height="100%" justifyContent="center">
              <img src={Warehouse} alt="warehouse" height="310px" />
            </Stack>
          </Grid>
        </Grid>

        <ButtonForm
          comeBack={comeBack}
          buttonName={i18n.t("buttons.next")}
          formik={formik}
        />
      </form>
    </TransportOrderWizardTemplate>
  );
};

export default Details;
