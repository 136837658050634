import React from "react";
import BoxInformation from "@shared/ui/molecules/BoxInformation/BoxInformation";
import StarsIcon from "@shared/ui/atoms/icons/StarsIcon";
import routes from "@routes/routes";
import { useTranslation } from "react-i18next";

const ReviewCreated: React.FC<any> = () => {
  const { i18n } = useTranslation();

  const mainPage = () => {
    return routes.root();
  };

  return (
    <BoxInformation
      seoProps={{
        title: "Potwierdzenie wystawienia opinii",
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
      actionLeftButton={null}
      actionRightButton={mainPage}
      image={<StarsIcon />}
      title={i18n.t("ui.complete_opinion.title")}
      description={i18n.t("ui.complete_opinion.description")}
      nameButtonLeft={null}
      nameButtonRight={i18n.t("buttons.understand")}
    />
  );
};

export default ReviewCreated;
