import { createRouting, segment, uuid, query } from "ts-routes";

const routes = createRouting({
  root: {
    ...segment`/pl/`,
    children: {
      blog: {
        ...segment`blog/`
      },
      transportOrders: {
        ...segment`zlecenia-transportowe/`,
        children: {
          new: {
            ...segment`nowe/`,
            children: {
              category: segment`kategorie/`,
              schedule: segment`harmonogram/`,
              details: segment`szczegoly/`,
              summary: segment`podsumowanie/`
            }
          },
          transportOrder: {
            ...segment`${uuid("id")}/`,
            children: {
              messages: segment`wiadomosci/`,
              offers: segment`oferty/`
            }
          }
        }
      },
      offers: {
        ...segment`oferty/`,
        children: {
          offer: segment`${uuid("id")}/`
        }
      },
      reviews: {
        ...segment`opinie/`,
        children: {
          new: {
            ...segment`nowe/`,
            query: {
              token: query("required")
            }
          }
        }
      },
      contact: segment`kontakt/`,
      termsOfService: segment`regulamin-serwisu/`,
      privacyPolicy: segment`polityka-prywatnosci/`,
      cookiePolicy: segment`polityka-cookies/`,
      serviceRates: segment`cennik/`,
      users: {
        ...segment`uzytkownicy/`,
        children: {
          user: segment`${uuid("id")}/`
        }
      },
      account: {
        ...segment`moje-konto/`,
        children: {
          sessions: segment`sesje/`,
          invoices: segment`faktury/`,
          resetPassword: {
            ...segment`zresetuj-haslo/`,
            query: {
              token: query("required")
            }
          },
          transportOrders: {
            ...segment`zlecenia-transportowe/`,
            children: {
              inactive: segment`nieaktywne/`
            }
          },
          confirmEmail: {
            ...segment`potwierdz-email/`,
            query: {
              token: query("required")
            }
          },
          offers: {
            ...segment`oferty/`,
            children: {
              offer: segment`${uuid("id")}/`
            }
          },
          purchaseOrders: {
            ...segment`zamowienia-zakupu/`,
            children: {
              purchaseOrder: segment`${uuid("id")}/`
            }
          }
        }
      },
      help: {
        ...segment`pomoc/`,
        children: {
          whatAreTheBenefitsOfTransking: segment`jakie-korzysci-daje-mi-transking-eu/`,
          howToCreateAccount: segment`jak-zalozyc-konto/`,
          forgotMyPassword: segment`zapomnialem-hasla/`,
          howToCreateAnOrder: segment`jak-stworzyc-zlecenie/`,
          howToSearchForOrders: segment`jak-wyszukac-zlecenia/`,
          howTranskingWorks: segment`jak-dziala-transking-eu/`,
          howCanIPayForTheOffer: segment`w-jaki-sposob-moge-oplacic-oferte/`,
          whereCanISeeOffersFromCarriers: segment`gdzie-moge-podejrzec-oferty-od-przewoznikow/`,
          howCanIMakeAnOfferAsACarriers: segment`w-jaki-sposob-jako-przewoznik-moge-wystawic-oferte-zleceniodawcy/`,
          howCanIContactYouRegardingTheOffer: segment`jak-moge-skontaktowac-sie-w-sprawie-zlecenia/`,
          canIExchangeMyContactDetails: segment`czy-moge-wymieniac-sie-swoimi-danymi-kontaktowymi/`,
          doIHaveToChooseTheBestOffer: segment`czy-musze-wybrac-najkorzystniejsza-oferte/`,
          whatHappensAfterTheOfferIsPaidFor: segment`co-dzieje-sie-po-oplaceniu-oferty/`,
          howToGiveAnOpinionToTheCarrier: segment`jak-wystawic-opinie-przewoznikowi/`,
          whereCanIViewAllMyIssuedOrders: segment`gdzie-moge-podejrzec-wszystkie-swoje-wystawione-zlecenia/`
        }
      },
      emailConfirmed: segment`zweryfikowano-adres-email/`,
      resetPasswordCompleted: segment`zresetowano-haslo/`,
      transportOrderCreated: {
        ...segment`utworzono-zlecenie-transportowe/`,
        query: {
          transport_order_id: query("required")
        }
      },
      reviewCreated: {
        ...segment`utworzono-opinie/`
      },
      checkoutCompleted: {
        ...segment`zakonczono-realizacje-zamowienia/`,
        query: {
          purchase_order_id: query("required")
        }
      }
    }
  }
});

export default routes;
