import React from "react";
import { Link as RouterLink, NavLink, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import routes from "@routes/routes";
import Link from "@shared/ui/atoms/Link";
import { selectCurrentUser, removeCredentials } from "@store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "@shared/ui/molecules/UserAvatar/UserAvatar";
import { useDeleteSessionsMutation } from "@api/TranstubeCore/sessionsApi";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@shared/ui/atoms/Typography";
import CustomerIcon from "@shared/ui/atoms/icons/CustomerIcon";
import MonitorIcon from "@shared/ui/atoms/icons/MonitorIcon/MonitorIcon";
import LogoutIcon from "@shared/ui/atoms/icons/LogoutIcon/LogoutIcon";
import OrdersIcon from "@shared/ui/atoms/icons/OrdersIcon/OrdersIcon";
import AvatarMenu from "@shared/ui/molecules/AvatarMenu";
import ButtonSignIn from "@shared/ui/atoms/Dialog/ButtonSignIn/ButtonSignIn";
import ButtonSignUp from "@shared/ui/atoms/Dialog/ButtonSignUp/ButtonSignUp";
import TransactionIcon from "@shared/ui/atoms/icons/TransactionIcon";
import OfferIcon from "@shared/ui/atoms/icons/OfferIcon/OfferIcon";
import logo from "@assets/images/christmas_logo.svg";
import InvoiceIcon from "@shared/ui/atoms/icons/InvoiceIcon";
import { Trans } from "react-i18next";
import ButtonAppBarCollapse from "../ButtonAppBarCollapse";
import useStyles from "./AppBarCollapse.styles";

const AppBarCollapse: React.FC = () => {
  const { classes } = useStyles();
  const currentUser = useSelector(selectCurrentUser);

  const [deleteSession] = useDeleteSessionsMutation();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const history = useHistory();

  const handleClickDestroySession = async () => {
    if (currentUser !== null) {
      await deleteSession({
        id: currentUser.meta.current_session_id
      })
        .unwrap()
        .then(() => {
          dispatch(removeCredentials());
        });
    }
  };

  const reviewsUser = () => {
    return history.push(routes.root.users.user({ id: currentUser.id }));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseItem = () => {
    setAnchorEl(null);
    reviewsUser();
  };

  const loggedUser = () => {
    if (currentUser === null) {
      return (
        <>
          <ButtonSignIn />
          <ButtonSignUp />
        </>
      );
    }

    return (
      <Box display="flex">
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Panel użytkownika">
            <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
              <UserAvatar color={currentUser.avatarColor}>
                {currentUser.displayName}
              </UserAvatar>
            </IconButton>
          </Tooltip>
        </Box>

        <AvatarMenu anchorEl={anchorEl} handleClose={handleClose}>
          <MenuItem onClick={handleCloseItem}>
            <Box display="inline-block">
              <Typography variant="body2" color="text.turquoise.dark">
                <strong>{currentUser.name}</strong>
              </Typography>
              <Typography variant="body2" color="text.grey.main">
                {currentUser.email}
              </Typography>
            </Box>
          </MenuItem>
          <Divider sx={{ borderStyle: "dashed", borderColor: "#dadada" }} />
          <NavLink to={routes.root.account()} className={classes.navLink} exact>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <CustomerIcon fontSize="small" />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje konto
              </Typography>
            </MenuItem>
          </NavLink>
          <NavLink
            to={routes.root.account.sessions()}
            className={classes.navLink}
            exact
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <MonitorIcon fontSize="small" />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Sesje
              </Typography>
            </MenuItem>
          </NavLink>
          <NavLink
            to={routes.root.account.transportOrders()}
            className={classes.navLink}
            exact
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <OrdersIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje zlecenia
              </Typography>
            </MenuItem>
          </NavLink>
          <NavLink
            to={routes.root.account.purchaseOrders()}
            className={classes.navLink}
            exact
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <TransactionIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje zakupy
              </Typography>
            </MenuItem>
          </NavLink>
          <NavLink
            to={routes.root.account.offers()}
            className={classes.navLink}
            exact
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <OfferIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje oferty
              </Typography>
            </MenuItem>
          </NavLink>
          <NavLink
            to={routes.root.account.invoices()}
            className={classes.navLink}
            exact
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <InvoiceIcon />
              </ListItemIcon>
              <Typography
                variant="body2"
                color="text.turquoise.dark"
                sx={{
                  textDecorationLine: "none"
                }}
              >
                Moje faktury
              </Typography>
            </MenuItem>
          </NavLink>
          <Divider
            sx={{ borderStyle: "dashed", borderColor: "#dadada", mt: 1, mb: 1 }}
          />
          <MenuItem
            onClick={() => {
              handleClickDestroySession();
              handleClose();
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            Wyloguj się
          </MenuItem>
        </AvatarMenu>
      </Box>
    );
  };

  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        <RouterLink to={routes.root()} className={classes.logoLink}>
          <img src={logo} alt="logo" />
        </RouterLink>
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Link
          variant="body2"
          component={RouterLink}
          underline="none"
          color="text.turquoise.dark"
          to={routes.root()}
          sx={{ minWidth: 100 }}
        >
          <strong>
            <Trans i18nKey="ui.menu.home" />
          </strong>
        </Link>

        <Link
          variant="body2"
          component={RouterLink}
          underline="none"
          color="text.turquoise.dark"
          to={routes.root.transportOrders.new.category()}
          sx={{ minWidth: 100 }}
        >
          <strong>
            <Trans i18nKey="ui.menu.new_order" />
          </strong>
        </Link>

        <Link
          variant="body2"
          component={RouterLink}
          underline="none"
          color="text.turquoise.dark"
          to={routes.root.transportOrders()}
          sx={{ minWidth: 100 }}
        >
          <strong>
            <Trans i18nKey="ui.menu.orders" />
          </strong>
        </Link>

        {loggedUser()}
      </Stack>

      <ButtonAppBarCollapse />
    </>
  );
};

export default AppBarCollapse;
