import React from "react";
import TransportOrder from "@shared/ui/organisms/Order/TransportOrder";
import OrderTemplate from "@shared/ui/templates/OrderTemplate";
import { useGetPublicTransportOrderQuery } from "@api/TranstubeCore/transportOrdersApi";
import { useParams } from "react-router-dom";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";

const OrderPage: React.FC = () => {
  const { id: transportOrderId } = useParams<any>();

  const { data: publicTransportOrderResult = { data: undefined }, error = {} } =
    useGetPublicTransportOrderQuery({ id: transportOrderId });

  const { data: transportOrder } = publicTransportOrderResult;

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  if (!transportOrder) return <LinearDeterminate />;

  return (
    <OrderTemplate
      seoProps={{
        title: "Szczegóły zlecenia przewozu",
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      <TransportOrder transportOrder={transportOrder} />
    </OrderTemplate>
  );
};

export default OrderPage;
