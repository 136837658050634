import React from "react";
import BoxInformation from "@shared/ui/molecules/BoxInformation/BoxInformation";
import routes from "@routes/routes";
import PaymentIcon from "@shared/ui/atoms/icons/PaymentIcon/PaymentIcon";
import useQueryParams from "@shared/hooks/useQueryParams";
import { useTranslation } from "react-i18next";

const CompleteOrder: React.FC<any> = () => {
  const { i18n } = useTranslation();
  const { purchase_order_id: purchaseOrderId } = useQueryParams<any>();

  const detailsOrder = () => {
    return routes.root.account.purchaseOrders.purchaseOrder({
      id: purchaseOrderId
    });
  };

  const mainPage = () => {
    return routes.root.transportOrders.new.schedule();
  };

  return (
    <BoxInformation
      seoProps={{
        title: "Potwierdzenie płatności",
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
      actionLeftButton={detailsOrder}
      actionRightButton={mainPage}
      image={<PaymentIcon />}
      title={i18n.t("ui.complete_payment.title")}
      description={i18n.t("ui.complete_payment.description")}
      nameButtonLeft={i18n.t("buttons.purchase_order")}
      nameButtonRight={i18n.t("buttons.new_order")}
    />
  );
};

export default CompleteOrder;
