import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link as RouterLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import Link from "@shared/ui/atoms/Link";
import EmailIcon from "@shared/ui/atoms/icons/EmailIcon";
import YoutubeIcon from "@shared/ui/atoms/icons/YoutubeIcon";
import TwitterIcon from "@shared/ui/atoms/icons/TwitterIcon";
import InstagramIcon from "@shared/ui/atoms/icons/InstagramIcon";
import FacebookIcon from "@shared/ui/atoms/icons/FacebookIcon";
import Divider from "@mui/material/Divider";
import logo from "@assets/images/christmas_logo.svg";
import { Trans } from "react-i18next";
import ContactMailLink from "@shared/ui/molecules/ContactMailLink";
import useStyles from "./Footer.styles";

const Footer: React.FC<any> = () => {
  const { classes } = useStyles();
  const current = new Date();
  const date = `${current.getFullYear()}`;

  return (
    <footer className={classes.root}>
      <Container>
        <Grid container pt={7}>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} pb={2}>
            <Stack spacing={1}>
              <Box className={classes.boxLogo} pr={2}>
                <img src={logo} alt="logo" />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <Stack spacing={2}>
              <Typography variant="h6" color="text.turquoise.dark">
                Produkt
              </Typography>
              <Stack pb={4}>
                <Stack spacing={1} alignItems="baseline">
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="hover"
                    color="text.turquoise.dark"
                    to={routes.root()}
                  >
                    <Trans i18nKey="ui.menu.home" />
                  </Link>

                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="hover"
                    color="text.turquoise.dark"
                    to={routes.root.transportOrders.new.category()}
                  >
                    <Trans i18nKey="ui.menu.new_order" />
                  </Link>

                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="hover"
                    color="text.turquoise.dark"
                    to={routes.root.transportOrders()}
                  >
                    <Trans i18nKey="ui.menu.orders" />
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <Stack spacing={2}>
              <Typography variant="h6" color="text.turquoise.dark">
                Informacje
              </Typography>

              <Stack pb={4}>
                <Stack spacing={1} alignItems="baseline">
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="hover"
                    color="text.turquoise.dark"
                    to={routes.root.serviceRates()}
                  >
                    <Trans i18nKey="pages.serviceRates.title" />
                  </Link>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="hover"
                    color="text.turquoise.dark"
                    to={routes.root.help()}
                  >
                    <Trans i18nKey="pages.help.title" />
                  </Link>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="hover"
                    color="text.turquoise.dark"
                    to={routes.root.contact()}
                  >
                    <Trans i18nKey="pages.contact.title" />
                  </Link>
                  <Link
                    variant="body2"
                    component="a"
                    underline="hover"
                    color="text.turquoise.dark"
                    href={routes.root.blog()}
                  >
                    <Trans i18nKey="pages.blog.title" />
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <Box display="inline-block">
              <Box>
                <Stack spacing={2}>
                  <Typography variant="h6" color="text.turquoise.dark">
                    Masz pytania?
                  </Typography>

                  <Stack direction="row" spacing={1}>
                    <Stack pt={0.2}>
                      <EmailIcon />
                    </Stack>
                    <Stack pb={4}>
                      <ContactMailLink variant="body2" />
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
              <Box>
                <Stack spacing={2}>
                  <Typography variant="h6" color="text.turquoise.dark">
                    Dołącz do Nas
                  </Typography>
                  <Box className={classes.centerBox}>
                    <Stack direction="row" spacing={2}>
                      <Box className={classes.box} textAlign="center">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/TransKingEU"
                          rel="noreferrer"
                        >
                          <FacebookIcon className="icon" />
                        </a>
                      </Box>
                      <Box className={classes.box} textAlign="center">
                        <a
                          target="_blank"
                          href="https://www.instagram.com/TransKingEU"
                          rel="noreferrer"
                        >
                          <InstagramIcon className="icon" />
                        </a>
                      </Box>

                      <Box className={classes.box} textAlign="center">
                        <a
                          target="_blank"
                          href="https://twitter.com/TransKingEU"
                          rel="noreferrer"
                        >
                          <TwitterIcon className="icon" />
                        </a>
                      </Box>

                      <Box className={classes.box} textAlign="center">
                        <a
                          target="_blank"
                          href="https://www.youtube.com/@TransKingEU"
                          rel="noreferrer"
                        >
                          <YoutubeIcon className="icon" />
                        </a>
                      </Box>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={2}
            xl={2}
            pb={4}
            pl={1}
            pr={2}
          />
        </Grid>
      </Container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        display={{ xs: "none", sm: "none", md: "block" }}
      >
        <Divider light />
        <Container>
          <Box
            sx={{ display: "flex", justifyContent: "space-between" }}
            pt={3}
            pb={3}
          >
            <Box>
              <Typography variant="body3" color="text.grey.main">
                &copy; Copyright {date} TransKing.eu
              </Typography>
            </Box>
            <Box>
              <Stack spacing={2} direction="row">
                <Link
                  variant="body3"
                  component={RouterLink}
                  underline="hover"
                  color="text.turquoise.dark"
                  to={routes.root.termsOfService()}
                >
                  <strong>Regulamin serwisu</strong>
                </Link>
                <Link
                  variant="body3"
                  component={RouterLink}
                  underline="hover"
                  color="text.turquoise.dark"
                  to={routes.root.privacyPolicy()}
                >
                  <strong>Polityka prywatności</strong>
                </Link>
                <Link
                  variant="body3"
                  component={RouterLink}
                  underline="hover"
                  color="text.turquoise.dark"
                  to={routes.root.cookiePolicy()}
                >
                  <strong>Polityka cookies</strong>
                </Link>
              </Stack>
            </Box>
          </Box>
        </Container>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        display={{ xs: "block", sm: "block", md: "none" }}
      >
        <Divider light />
        <Container>
          <Stack spacing={2} pt={3} pb={3}>
            <Link
              variant="body3"
              component={RouterLink}
              underline="hover"
              color="text.turquoise.dark"
              to={routes.root.termsOfService()}
            >
              <strong>Regulamin serwisu</strong>
            </Link>
            <Link
              variant="body3"
              component={RouterLink}
              underline="hover"
              color="text.turquoise.dark"
              to={routes.root.privacyPolicy()}
            >
              <strong>Polityka prywatności</strong>
            </Link>
            <Link
              variant="body3"
              component={RouterLink}
              underline="hover"
              color="text.turquoise.dark"
              to={routes.root.cookiePolicy()}
            >
              <strong>Polityka cookies</strong>
            </Link>
            <Typography variant="body3" color="text.grey.main">
              &copy; Copyright {date} TransKing.eu
            </Typography>
          </Stack>
        </Container>
      </Grid>
    </footer>
  );
};

export default Footer;
