import React from "react";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import { Box, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Rating from "@mui/material/Rating";
import UserNameBadge from "@shared/ui/molecules/UserNameBadge/UserNameBadge";
import Typography from "@shared/ui/atoms/Typography";
import Panel from "@shared/ui/molecules/Panel/Panel";
import { useGetPublicReviewsQuery } from "@api/TranstubeCore/reviewsApi";
import { useHistory, useParams } from "react-router-dom";
import { useGetPublicUserQuery } from "@api/TranstubeCore/usersApi";
import convertDate from "@shared/utils/convertDate";
import routes from "@routes/routes";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";
import { useTranslation } from "react-i18next";

const UserProfileDetails: React.FC = () => {
  const history = useHistory();
  const { id }: any = useParams();
  const { i18n } = useTranslation();

  const { data: publicUserResult = { data: undefined }, error = {} } =
    useGetPublicUserQuery({
      id
    });

  const { data: user } = publicUserResult;

  const { data: reviewsResult = { data: undefined } } =
    useGetPublicReviewsQuery({
      params: {
        filter: {
          reviewed_user_id_eq: id
        }
      }
    });

  const { data: reviews } = reviewsResult;

  const open = (transportOrderId: any) => {
    history.push(
      routes.root.transportOrders.transportOrder({ id: transportOrderId })
    );
  };

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  if (!reviews || !user) return <LinearDeterminate />;

  return (
    <ContainerTemplate
      seoProps={{
        title: i18n.t("pages.userProfileDetails.head.title", {
          name: user.displayName
        }),
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      <Grid container mt={3} mb={3} spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Panel>
            <UserNameBadge
              to={routes.root.users.user({ id: user.id })}
              color={user.avatarColor}
              displayName={user.displayName}
              numberRatings={user.meta.review_count}
              averageRating={user.meta.average_rating}
            />
          </Panel>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Panel>
            <Box sx={{ pb: 2 }}>
              <Typography variant="h5" color="text.turquoise.dark" align="left">
                Ocena użytkownika ({user.meta.review_count})
              </Typography>
            </Box>

            <Stack spacing={2} divider={<Divider light />}>
              {reviews.map((review: any) => (
                <Stack spacing={2} key={review.id}>
                  <Box sx={{ display: "flex" }}>
                    <UserNameBadge
                      to={routes.root.users.user({
                        id: review.user.id
                      })}
                      color={review.user.avatarColor}
                      displayName={review.user.displayName}
                      numberRatings={review.user.meta.review_count}
                      averageRating={review.user.meta.average_rating}
                    />
                  </Box>

                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="caption" color="text.grey.main">
                      Ocena:
                    </Typography>

                    <Rating
                      sx={{ fontSize: "14px" }}
                      defaultValue={parseFloat(review.rating)}
                      precision={0.5}
                      readOnly
                    />
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "inline-block" }}>
                      <Typography variant="body2" color="text.grey.main">
                        {review.content}
                      </Typography>
                      <Box display="flex" sx={{ pt: 1.5 }}>
                        <Stack spacing={0.5} direction="row">
                          <Typography variant="caption" color="text.grey.main">
                            Nr zlecenia:
                          </Typography>
                          <Typography
                            variant="caption"
                            color="text.grey.main"
                            onClick={() => open(review.transportOrder.id)}
                            sx={{
                              cursor: "pointer",
                              textDecoration: "underline"
                            }}
                          >
                            {review.transportOrder.number}
                          </Typography>
                          <Typography variant="caption" color="text.grey.main">
                            /
                          </Typography>
                          <Typography variant="caption" color="text.grey.main">
                            data utworzenia:
                          </Typography>

                          <Typography variant="caption" color="text.grey.main">
                            {convertDate(review.createdAt)}
                          </Typography>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Panel>
        </Grid>
      </Grid>
    </ContainerTemplate>
  );
};

export default UserProfileDetails;
