import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useHistory, useParams } from "react-router-dom";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TextField from "@shared/ui/atoms/TextField";
import { useGetOfferQuery } from "@api/TranstubeCore/offersApi";
import { useCreateCheckoutMutation } from "@api/TranstubeCore/checkoutsApi";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import UserNameBadge from "@shared/ui/molecules/UserNameBadge/UserNameBadge";
import CoffeeIcon from "@shared/ui/atoms/icons/CoffeeIcon";
import StopSignIcon from "@shared/ui/atoms/icons/StopSignIcon";
import { useTheme } from "@mui/material/styles";
import Panel from "@shared/ui/molecules/Panel/Panel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { formattedCurrencyPrice } from "@shared/utils/currencyPrice";
import LinearDeterminate from "@shared/ui/molecules/LinearDeterminate/LinearDeterminate";
import NoMatch from "@pages/noMatch/NoMatch";
import PaymentIcon from "@shared/ui/atoms/icons/PaymentIcon/PaymentIcon";
import { useFormik } from "formik";
import LoadingButton from "@shared/ui/atoms/LoadingButton";
import Button from "@shared/ui/atoms/Button";
import calculatePrice from "@shared/utils/calculatePrice";
import { formattedTotalCurrencyPrice } from "@shared/utils/totalCurrencyPrice";
import { Trans } from "react-i18next";
import useStyles from "./Offer.styles";
import validationSchema from "./Offer.validationSchema";

const Offer: React.FC = () => {
  const { id }: any = useParams();
  const { classes } = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [createCheckout] = useCreateCheckoutMutation();

  const { data: offerResult = { data: undefined }, error = {} } =
    useGetOfferQuery({ id });

  const { data: offer } = offerResult;

  const order = () => {
    if (!offer) return;

    history.push(
      routes.root.transportOrders.transportOrder.offers({
        id: offer.auction.transportOrder.id
      })
    );
  };

  const handleSubmit = async (values: any) => {
    if (!offer?.commission) return;

    let createCheckoutParams;

    if (values.coffeeOption === "addMoreCoffee") {
      createCheckoutParams = {
        body: {
          data: {
            attributes: {
              commission_line_item: {
                commission_id: offer.commission?.id
              },
              coffee_line_item: {
                gross_price: values.coffeePrice
              }
            }
          }
        }
      };
    } else if (values.coffeeOption === "addNoCoffee") {
      createCheckoutParams = {
        body: {
          data: {
            attributes: {
              commission_line_item: {
                commission_id: offer.commission.id
              }
            }
          }
        }
      };
    } else {
      createCheckoutParams = {
        body: {
          data: {
            attributes: {
              commission_line_item: {
                commission_id: offer.commission.id
              },
              coffee_line_item: {
                gross_price: String(calculatePrice(values.coffeeOption, offer))
              }
            }
          }
        }
      };
    }

    await createCheckout(createCheckoutParams)
      .unwrap()
      .then((payload) => {
        window.location.assign(payload.data.url);
      });
  };

  const formik = useFormik({
    initialValues: {
      coffeePrice: "1",
      coffeeOption: "addNoCoffee"
    },
    validationSchema: validationSchema(),
    onSubmit: handleSubmit
  });

  const showPriceForMoreCoffees = () => {
    if (!offer?.commission) return null;

    if (formik.values.coffeeOption === "addMoreCoffee") {
      return formattedCurrencyPrice(
        formik.values.coffeePrice,
        offer.commission.currencyIsoCode
      );
    }

    return formattedCurrencyPrice(0, offer.commission.currencyIsoCode);
  };

  const displayCoffeePriceInTheList = () => {
    if (!offer?.commission) return null;

    if (formik.values.coffeeOption === "addNoCoffee") {
      return (
        <Typography variant="body2" color="text.turquoise.dark" sx={{ pl: 2 }}>
          <strong>
            {formattedCurrencyPrice(
              calculatePrice(formik.values.coffeeOption, offer),
              offer.commission.currencyIsoCode
            )}
          </strong>
        </Typography>
      );
    }

    if (formik.values.coffeeOption === "addTwoCoffee") {
      return (
        <Typography variant="body2" color="text.turquoise.dark" sx={{ pl: 2 }}>
          <strong>
            {formattedCurrencyPrice(
              calculatePrice(formik.values.coffeeOption, offer),
              offer.commission.currencyIsoCode
            )}
          </strong>
        </Typography>
      );
    }

    if (formik.values.coffeeOption === "addThreeCoffee") {
      return (
        <Typography variant="body2" color="text.turquoise.dark" sx={{ pl: 2 }}>
          <strong>
            {formattedCurrencyPrice(
              calculatePrice(formik.values.coffeeOption, offer),
              offer.commission.currencyIsoCode
            )}
          </strong>
        </Typography>
      );
    }

    if (formik.values.coffeeOption === "addMoreCoffee") {
      return (
        <Typography variant="body2" color="text.turquoise.dark" sx={{ pl: 2 }}>
          <strong>{showPriceForMoreCoffees()}</strong>
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.turquoise.dark" sx={{ pl: 2 }}>
        <strong>0 {offer && offer?.commission?.currencyIsoCode}</strong>
      </Typography>
    );
  };

  const description = () => {
    return (
      <Box pt={2}>
        <Box>
          <Stack direction="row" spacing={2}>
            <FiberManualRecordIcon
              sx={{ fontSize: "10px", alignSelf: "center" }}
            />
            <Typography variant="body2" color="text.grey.main">
              Dołóż swoją cegiełke do rozwoju tej aplikacji
            </Typography>
          </Stack>
        </Box>
        <Box>
          <Stack direction="row" spacing={2}>
            <FiberManualRecordIcon
              sx={{ fontSize: "10px", alignSelf: "center" }}
            />
            <Typography variant="body2" color="text.grey.main">
              Dzięki nam szybko załatwisz sobie przejazd
            </Typography>
          </Stack>
        </Box>
      </Box>
    );
  };

  const textFieldCoffee = () => {
    if (formik.values.coffeeOption === "addMoreCoffee") {
      return (
        <Box pt={2} pb={2}>
          <Stack spacing={1}>
            <Typography variant="body2" color="text.turquoise.dark">
              <strong>Wpisz kwotę kawy</strong>
            </Typography>

            <TextField
              name="coffeePrice"
              value={formik.values.coffeePrice}
              onChange={formik.handleChange}
              error={
                formik.touched.coffeePrice && Boolean(formik.errors.coffeePrice)
              }
              helperText={
                formik.touched.coffeePrice && formik.errors.coffeePrice
              }
              placeholder="Wpisz kwotę kawy"
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  {
                    display: "none"
                  },
                "& input[type=number]": {
                  MozAppearance: "textfield"
                }
              }}
              type="number"
            />
          </Stack>
        </Box>
      );
    }

    return <></>;
  };

  if ("status" in error && error.status === 404) {
    return <NoMatch />;
  }

  if (!offer?.commission) return <LinearDeterminate />;

  return (
    <ContainerTemplate
      seoProps={{
        title: `Szczegóły oferty ${offer.user.displayName}`,
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Panel sx={{ minHeight: "140px" }}>
              <Stack spacing={2}>
                <Box display="flex">
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="body2"
                      color="text.turquoise.dark"
                      textAlign="left"
                    >
                      <strong>Nazwa przewoźnika</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%" }}>
                    <Typography
                      variant="body2"
                      color="text.turquoise.dark"
                      textAlign="right"
                    >
                      <strong>Cena oferty</strong>
                    </Typography>
                  </Box>
                </Box>

                <Divider variant="middle" light />

                <Box display="flex">
                  <Box
                    sx={{
                      width: "50%"
                    }}
                    textAlign="left"
                  >
                    <UserNameBadge
                      to={routes.root.users.user({
                        id: offer?.user?.id
                      })}
                      color={offer?.user?.avatarColor}
                      displayName={offer?.user?.displayName}
                      numberRatings={offer?.user?.meta?.review_count}
                      averageRating={offer?.user?.meta?.average_rating}
                    />
                  </Box>
                  <Box
                    sx={{ width: "50%" }}
                    textAlign="right"
                    alignSelf="center"
                  >
                    <Typography variant="body2" color="text.grey.main">
                      {formattedCurrencyPrice(
                        parseFloat(offer.price),
                        offer.commission.currencyIsoCode
                      )}
                    </Typography>
                  </Box>
                </Box>

                <Divider variant="middle" light />

                <Box display="flex">
                  <Box sx={{ width: "50%" }} textAlign="left">
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Prowizja</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%", textAlign: "right" }}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        {formattedCurrencyPrice(
                          parseFloat(offer.commission.grossPrice),
                          offer.commission.currencyIsoCode
                        )}
                      </strong>
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Panel>
          </Grid>
          <Grid item xs={12} md={4}>
            <Panel sx={{ minHeight: "150px" }}>
              <Stack spacing={2}>
                <Box
                  sx={{
                    display: "grid",
                    gridAutoColumns: "1fr",
                    gap: 1
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Postaw nam kawę</strong>
                    </Typography>
                  </Box>
                </Box>
                <RadioGroup
                  name="coffeeOption"
                  value={formik.values.coffeeOption}
                  onChange={formik.handleChange}
                >
                  <Box
                    sx={{
                      display: "flex"
                    }}
                  >
                    <Box sx={{ width: "25%" }}>
                      <FormControlLabel
                        label=""
                        value="addNoCoffee"
                        control={
                          <Radio
                            checkedIcon={
                              <Box
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  border: 2,
                                  width: "3rem",
                                  height: "3rem",
                                  cursor: "pointer"
                                }}
                              >
                                <StopSignIcon
                                  className={classes.iconMoney}
                                  role="presentation"
                                />
                              </Box>
                            }
                            icon={
                              <Box
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  border: 1,
                                  borderColor: theme.palette.border.grey.main,
                                  width: "3rem",
                                  height: "3rem",
                                  cursor: "pointer"
                                }}
                              >
                                <StopSignIcon
                                  className={classes.iconMoney}
                                  role="presentation"
                                />
                              </Box>
                            }
                            inputProps={{ "aria-label": "A" }}
                          />
                        }
                      />
                    </Box>
                    <Box sx={{ width: "25%" }}>
                      <Box display="inline-block">
                        <FormControlLabel
                          value="addTwoCoffee"
                          label=""
                          control={
                            <Radio
                              checkedIcon={
                                <Box
                                  sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    border: 2,
                                    width: "3rem",
                                    height: "3rem",
                                    cursor: "pointer"
                                  }}
                                >
                                  <CoffeeIcon
                                    className={classes.iconMoney}
                                    role="presentation"
                                  />
                                </Box>
                              }
                              icon={
                                <Box
                                  sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    border: 1,
                                    borderColor: theme.palette.border.grey.main,
                                    width: "3rem",
                                    height: "3rem",
                                    cursor: "pointer"
                                  }}
                                >
                                  <CoffeeIcon
                                    className={classes.iconMoney}
                                    role="presentation"
                                  />
                                </Box>
                              }
                              inputProps={{ "aria-label": "A" }}
                            />
                          }
                        />
                        <Typography
                          variant="body2"
                          color="text.grey.main"
                          sx={{ pl: 1.5 }}
                        >
                          <strong>10%</strong>
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ width: "25%" }}>
                      <Box display="inline-block">
                        <FormControlLabel
                          value="addThreeCoffee"
                          label=""
                          control={
                            <Radio
                              checkedIcon={
                                <Box
                                  sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    border: 2,

                                    width: "3rem",
                                    height: "3rem",
                                    cursor: "pointer"
                                  }}
                                >
                                  <CoffeeIcon
                                    className={classes.iconMoney}
                                    role="presentation"
                                  />
                                </Box>
                              }
                              icon={
                                <Box
                                  sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    border: 1,
                                    borderColor: theme.palette.border.grey.main,
                                    width: "3rem",
                                    height: "3rem",
                                    cursor: "pointer"
                                  }}
                                >
                                  <CoffeeIcon
                                    className={classes.iconMoney}
                                    role="presentation"
                                  />
                                </Box>
                              }
                              inputProps={{ "aria-label": "A" }}
                            />
                          }
                        />
                        <Typography
                          variant="body2"
                          color="text.grey.main"
                          sx={{ pl: 1.5 }}
                        >
                          <strong>25%</strong>
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ width: "25%" }}>
                      <FormControlLabel
                        value="addMoreCoffee"
                        label=""
                        control={
                          <Radio
                            checkedIcon={
                              <Box
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  border: 2,

                                  width: "3rem",
                                  height: "3rem",
                                  cursor: "pointer"
                                }}
                              >
                                <PaymentIcon
                                  className={classes.iconMoney}
                                  role="presentation"
                                />
                              </Box>
                            }
                            icon={
                              <Box
                                sx={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  border: 1,
                                  borderColor: theme.palette.border.grey.main,
                                  width: "3rem",
                                  height: "3rem",
                                  cursor: "pointer"
                                }}
                              >
                                <PaymentIcon
                                  className={classes.iconMoney}
                                  role="presentation"
                                />
                              </Box>
                            }
                          />
                        }
                      />
                    </Box>
                  </Box>
                </RadioGroup>
              </Stack>
              {textFieldCoffee()}
              {description()}
            </Panel>

            <Divider variant="middle" light />

            <Panel>
              <Stack spacing={1}>
                <Box display="flex">
                  <Box sx={{ width: "50%" }} textAlign="left">
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Prowizja</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%" }} textAlign="right">
                    <Typography
                      variant="body2"
                      color="text.turquoise.dark"
                      sx={{ pl: 2 }}
                    >
                      <strong>
                        {formattedCurrencyPrice(
                          parseFloat(offer.commission.grossPrice),
                          offer.commission.currencyIsoCode
                        )}
                      </strong>
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box sx={{ width: "50%" }} textAlign="left">
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Kawa</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%" }} textAlign="right">
                    {displayCoffeePriceInTheList()}
                  </Box>
                </Box>

                <Box display="flex">
                  <Box sx={{ width: "50%" }} textAlign="left">
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>Suma</strong>
                    </Typography>
                  </Box>
                  <Box sx={{ width: "50%" }} textAlign="right">
                    <Typography variant="body2" color="text.turquoise.dark">
                      <strong>
                        {formattedTotalCurrencyPrice(
                          formik.values.coffeeOption,
                          offer,
                          formik
                        )}
                      </strong>
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Panel>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start"
              }}
              pt={2}
            >
              <Button variant="outlined" onClick={order}>
                <Trans i18nKey="buttons.return" />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end"
              }}
              pt={2}
            >
              <LoadingButton
                type="submit"
                loading={Boolean(formik.isSubmitting)}
              >
                Zapłać
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContainerTemplate>
  );
};

export default Offer;
