import React from "react";
import ContainerTemplate from "@shared/ui/templates/ContainerTemplate";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Link as RouterLink } from "react-router-dom";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import Link from "@shared/ui/atoms/Link";
import { useTranslation } from "react-i18next";
import { THelpTemplate } from "./HelpTemplate.types";

const HelpTemplate: React.FC<THelpTemplate> = (props) => {
  const { children, seoProps = {} } = props;
  const { i18n } = useTranslation();

  return (
    <ContainerTemplate
      seoProps={{
        ...seoProps,
        title: [seoProps.title, i18n.t("pages.help.head.title")]
          .filter((value) => value !== undefined)
          .join(" - "),
        description:
          seoProps.description || i18n.t("pages.help.head.description")
      }}
    >
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <Stack spacing={1}>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Wstęp
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howTranskingWorks}
                  >
                    Jak działa TransKing.eu?
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.whatAreTheBenefitsOfTransking}
                  >
                    Jakie korzyści daje mi TransKing.eu?
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Konto
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howToCreateAccount}
                  >
                    Jak założyć konto?
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.forgotMyPassword}
                  >
                    Zapomniałem hasła
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Zlecenia
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howToCreateAnOrder}
                  >
                    Jak stworzyć zlecenie?
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howToSearchForOrders}
                  >
                    Jak wyszukać zlecenia?
                  </Link>
                </li>
              </ul>
            </ul>

            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Płatność
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howCanIPayForTheOffer}
                  >
                    W jaki sposób mogę opłacić ofertę?
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Oferty
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.whereCanISeeOffersFromCarriers}
                  >
                    Gdzie mogę podejrzeć oferty od przewoźników?
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howCanIMakeAnOfferAsACarriers}
                  >
                    W jaki sposób jako przewoźnik mogę wystawić ofertę
                    zleceniodawcy?
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Wiadomości
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howCanIContactYouRegardingTheOffer}
                  >
                    Jak mogę skontaktować się w sprawie zlecenia?
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.canIExchangeMyContactDetails}
                  >
                    Czy mogę wymieniać się swoimi danymi kontaktowymi?
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Akceptacja oferty
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.doIHaveToChooseTheBestOffer}
                  >
                    Czy muszę wybrać najkorzystniejszą ofertę?
                  </Link>
                </li>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.whatHappensAfterTheOfferIsPaidFor}
                  >
                    Co dzieje się po opłaceniu oferty?
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Panel użytkownika
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.whereCanIViewAllMyIssuedOrders}
                  >
                    Gdzie mogę podejrzeć wszystkie swoje wystawione zlecenia?
                  </Link>
                </li>
              </ul>
            </ul>
            <ul>
              <Typography variant="h6" color="text.turquoise.dark">
                Opinie
              </Typography>
              <ul style={{ listStyleType: "disc", color: "#1E99C0" }}>
                <li>
                  <Link
                    variant="body2"
                    component={RouterLink}
                    underline="none"
                    color="text.turquoise.dark"
                    to={routes.root.help.howToGiveAnOpinionToTheCarrier}
                  >
                    Jak wystawić opinię przewoźnikowi?
                  </Link>
                </li>
              </ul>
            </ul>
          </Stack>
        </Grid>
        {children}
      </Grid>
    </ContainerTemplate>
  );
};

export default HelpTemplate;
