import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { useHistory, useLocation } from "react-router-dom";
import TranstubeMap from "@shared/ui/atoms/TranstubeMap";
import { LngLatLike } from "maplibre-gl";
import Grid from "@mui/material/Grid";
import LocationAutocomplete from "@shared/ui/molecules/LocationAutocomplete";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Alert from "@shared/ui/atoms/Alert";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { useFormik } from "formik";
import { InputAdornment } from "@mui/material";
import OriginMarkerIcon from "@shared/ui/atoms/icons/OriginMarkerIcon";
import DestinationMarkerIcon from "@shared/ui/atoms/icons/DestinationMarkerIcon";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ButtonForm from "@shared/ui/molecules/ButtonForm";
import calculateDistanceKm from "@shared/utils/calculateDistanceKm";
import calculateDuration from "@shared/utils/calculateDuration";
import convertToLowerCase from "@shared/utils/convertToLowerCase";
import {
  useLazyGetLocationQuery,
  useLazyGetDirectionsQuery
} from "@api/TranstubeCore/mapApi";
import routes from "@routes/routes";
import Typography from "@shared/ui/atoms/Typography";
import formattedAddressWithComma from "@shared/utils/formattedAddressWithComma";
import Panel from "@shared/ui/molecules/Panel/Panel";
import TransportOrderWizardTemplate from "@shared/ui/templates/TransportOrderWizardTemplate";
import PanelHeader from "@shared/ui/atoms/PanelHeader";
import { Trans, useTranslation } from "react-i18next";
import DatePicker from "@shared/ui/molecules/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import useStyles from "./Schedule.styles";
import validationSchema from "./Schedule.validationSchema";
import categoryValidationSchema from "../category/Category.validationSchema";

const Android12Switch = styled(Switch)(() => ({
  padding: 8,
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#1E99C0"
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#D2EEF9"
  },
  "& .MuiSwitch-track": {
    backgroundColor: "#E9E9EA",
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16
    },
    "&:before": {
      left: 12
    },
    "&:after": {
      right: 12
    }
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2
  }
}));

const Schedule: React.FC = () => {
  const history = useHistory();
  const { classes } = useStyles();
  const location = useLocation();
  const routeParams: { [key: string]: any } = { ...location.state };
  const [getLocation] = useLazyGetLocationQuery();
  const [getDirections] = useLazyGetDirectionsQuery();
  const { i18n } = useTranslation();

  const handleSubmit = (values: any) => {
    history.replace(routes.root.transportOrders.new.details(), values);
  };

  const formik = useFormik({
    initialValues: {
      fromLocation: {
        id: null,
        address: "",
        latitude: null,
        longitude: null,
        formattedAddress: "",
        countryIsoCode: null
      },
      toLocation: {
        id: null,
        address: "",
        latitude: null,
        longitude: null,
        formattedAddress: "",
        countryIsoCode: null
      },
      isSpecifyShippingDates: false,
      startOn: null,
      endOn: null,
      duration: null,
      distance: null,
      polyline: null,
      ...routeParams
    },
    onSubmit: handleSubmit,
    validationSchema: validationSchema(i18n)
  });

  const detailsFromLoaction = async (event: any, option: any) => {
    await getLocation({ id: option.id })
      .unwrap()
      .then((payload) => {
        const formattedAddress = formattedAddressWithComma(payload.data);

        formik.setFieldValue("fromLocation", {
          ...formik.values.fromLocation,
          id: option.id,
          address: option.address,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,
          formattedAddress,
          countryIsoCode: payload.data.countryIsoCode
        });
      });
  };

  const detailsToLoaction = async (event: any, option: any) => {
    await getLocation({ id: option.id })
      .unwrap()
      .then((payload) => {
        const formattedAddress = formattedAddressWithComma(payload.data);

        formik.setFieldValue("toLocation", {
          ...formik.values.toLocation,
          id: option.id,
          address: option.address,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,
          formattedAddress,
          countryIsoCode: payload.data.countryIsoCode
        });
      });
  };

  const directionsGeolocations = async () => {
    if (
      formik.values.fromLocation.longitude === null ||
      formik.values.fromLocation.latitude === null ||
      formik.values.toLocation.longitude === null ||
      formik.values.fromLocation.latitude === null
    )
      return;

    const getDirectionsParams = {
      params: {
        origin: {
          latitude: formik.values.fromLocation.latitude,
          longitude: formik.values.fromLocation.longitude
        },
        destination: {
          latitude: formik.values.toLocation.latitude,
          longitude: formik.values.toLocation.longitude
        }
      }
    };

    await getDirections(getDirectionsParams)
      .unwrap()
      .then((payload) => {
        formik.setFieldValue("distance", payload.data.distance);
        formik.setFieldValue("duration", payload.data.duration);
        formik.setFieldValue("polyline", payload.data.polyline);
      });
  };

  useEffect(() => {
    directionsGeolocations();
  }, []);

  useEffect(() => {
    directionsGeolocations();
  }, [formik.values]);

  const prepareGeolocation = (mapLocation: any): LngLatLike | null => {
    if (mapLocation.longitude === null || mapLocation.latitude === null)
      return null;

    return [mapLocation.longitude, mapLocation.latitude];
  };

  const comeBack = () => {
    history.replace(routes.root.transportOrders.new.category(), {
      ...routeParams
    });
  };

  const flagFromLocation = () => {
    if (formik.values.fromLocation.countryIsoCode === null) return <></>;

    return (
      <Box pl={2}>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              formik.values.fromLocation.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              formik.values.fromLocation.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const flagToLocation = () => {
    if (formik.values.toLocation.countryIsoCode === null) return <></>;

    return (
      <Box pl={2}>
        <sup>
          <img
            loading="lazy"
            src={`https://flagcdn.com/w20/${convertToLowerCase(
              formik.values.toLocation.countryIsoCode
            )}.png`}
            srcSet={`https://flagcdn.com/w40/${convertToLowerCase(
              formik.values.toLocation.countryIsoCode
            )}.png 2x`}
            alt=""
            className={classes.flag}
          />
        </sup>
      </Box>
    );
  };

  const choice = () => {
    if (formik.values.isSpecifyShippingDates === true) {
      return (
        <Typography variant="body2" color="text.grey.main">
          <Trans i18nKey="labels.choice_yes" />
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.grey.main">
        <Trans i18nKey="labels.choice_no" />
      </Typography>
    );
  };

  const addressFromLocation = () => {
    if (formik?.values?.fromLocation?.formattedAddress !== "") {
      return (
        <Typography variant="body2" color="text.grey.main">
          {formik?.values?.fromLocation?.formattedAddress}
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.grey.main">
        -----
      </Typography>
    );
  };

  const addressToLocation = () => {
    if (formik?.values?.toLocation?.formattedAddress !== "") {
      return (
        <Typography variant="body2" color="text.grey.main">
          {formik?.values?.toLocation?.formattedAddress}
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="text.grey.main">
        -----
      </Typography>
    );
  };

  if (!categoryValidationSchema(i18n).isValidSync({ ...routeParams })) {
    history.replace(routes.root.transportOrders.new.category());

    return null;
  }

  return (
    <TransportOrderWizardTemplate
      step={1}
      seoProps={{
        robots: "nofollow, noindex, noarchive, nosnippet"
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Panel>
              <PanelHeader>Rozkład podróży</PanelHeader>

              <Stack spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>
                      <Trans i18nKey="labels.from_location" />
                    </strong>
                  </Typography>

                  <LocationAutocomplete
                    value={formik.values.fromLocation.address}
                    error={
                      formik.touched.fromLocation?.address &&
                      Boolean(formik.errors.fromLocation?.address)
                    }
                    helperText={
                      formik.touched.fromLocation?.address &&
                      formik.errors.fromLocation?.address
                    }
                    placeholder={i18n.t("labels.from_location")}
                    onChange={(event: any, newValue: any) => {
                      detailsFromLoaction(event, newValue);
                      formik.handleChange(event);
                    }}
                    onInputChange={() => {
                      formik.setFieldValue("fromLocation", {
                        id: null,
                        address: "",
                        latitude: null,
                        longitude: null,
                        formattedAddress: "",
                        countryIsoCode: null
                      });

                      formik.setFieldValue("duration", null);
                      formik.setFieldValue("distance", null);
                      formik.setFieldValue("polyline", null);
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <OriginMarkerIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>
                      <Trans i18nKey="labels.to_location" />
                    </strong>
                  </Typography>

                  <LocationAutocomplete
                    value={formik.values.toLocation.address}
                    error={
                      formik.touched.toLocation?.address &&
                      Boolean(formik.errors.toLocation?.address)
                    }
                    helperText={
                      formik.touched.toLocation?.address &&
                      formik.errors.toLocation?.address
                    }
                    placeholder={i18n.t("labels.to_location")}
                    onChange={(event: any, newValue: any) => {
                      detailsToLoaction(event, newValue);
                      formik.handleChange(event);
                    }}
                    onInputChange={() => {
                      formik.setFieldValue("toLocation", {
                        id: null,
                        address: "",
                        latitude: null,
                        longitude: null,
                        formattedAddress: "",
                        countryIsoCode: null
                      });

                      formik.setFieldValue("duration", null);
                      formik.setFieldValue("distance", null);
                      formik.setFieldValue("polyline", null);
                    }}
                    inputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DestinationMarkerIcon />
                        </InputAdornment>
                      )
                    }}
                  />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="body2" color="text.turquoise.dark">
                    <strong>
                      <Trans i18nKey="labels.exact_date" />
                    </strong>
                  </Typography>

                  <FormControlLabel
                    name="isSpecifyShippingDates"
                    control={<Android12Switch />}
                    label={choice()}
                    checked={formik.values.isSpecifyShippingDates}
                    onChange={formik.handleChange}
                  />
                </Stack>

                {!formik.values.isSpecifyShippingDates ? (
                  <Alert severity="info">
                    Twój termin jest elastyczny, będziesz musiał dopasować się
                    do przewoźnika.
                  </Alert>
                ) : (
                  <>
                    <Stack spacing={2}>
                      <div style={{ width: "100%" }}>
                        <Stack spacing={2}>
                          <Box>
                            <Stack spacing={1}>
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                <strong>
                                  <Trans i18nKey="labels.start_on" />
                                </strong>
                              </Typography>
                              <DatePicker
                                value={dayjs(formik.values.startOn)}
                                error={
                                  (formik.touched.startOn &&
                                    Boolean(formik.errors.startOn)) ||
                                  false
                                }
                                helperText={
                                  formik.touched.startOn &&
                                  formik.errors.startOn
                                }
                                onChange={(value: Dayjs) => {
                                  formik.setFieldValue(
                                    "startOn",
                                    value.format()
                                  );
                                }}
                                disablePast
                              />
                            </Stack>
                          </Box>
                          <Box>
                            <Stack spacing={1}>
                              <Typography
                                variant="body2"
                                color="text.turquoise.dark"
                              >
                                <strong>
                                  <Trans i18nKey="labels.end_on" />
                                </strong>
                              </Typography>
                              <DatePicker
                                value={dayjs(formik.values.endOn)}
                                onChange={(value: Dayjs) =>
                                  formik.setFieldValue("endOn", value.format())
                                }
                                error={
                                  (formik.touched.endOn &&
                                    Boolean(formik.errors.endOn)) ||
                                  false
                                }
                                helperText={
                                  formik.touched.endOn && formik.errors.endOn
                                }
                                disablePast
                              />
                            </Stack>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </>
                )}
              </Stack>
            </Panel>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={7}
            xl={7}
            display={{
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block"
            }}
          >
            <Stack direction="column" spacing={2}>
              <Panel p={1} sx={{ height: 340 }}>
                <TranstubeMap
                  originPosition={prepareGeolocation(
                    formik.values.fromLocation
                  )}
                  destinationPosition={prepareGeolocation(
                    formik.values.toLocation
                  )}
                  polyline={formik?.values?.polyline}
                />
              </Panel>
              <Panel>
                <PanelHeader>Szczegóły podróży</PanelHeader>

                <Stack spacing={2}>
                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <OriginMarkerIcon />

                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.from_location" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Box pl={1} display="flex">
                      {addressFromLocation()}
                      {flagFromLocation()}
                    </Box>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <DestinationMarkerIcon />

                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.to_location" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Box pl={1} display="flex">
                      {addressToLocation()}
                      {flagToLocation()}
                    </Box>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <AccessTimeFilledIcon className={classes.iconLabel} />

                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.duration" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Stack pl={1}>
                      <Typography variant="body2" color="text.grey.main">
                        {calculateDuration(formik.values.duration)}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack spacing={1}>
                    <Stack direction="row" spacing={1}>
                      <DirectionsCarIcon className={classes.iconLabel} />
                      <Typography variant="body2" color="text.turquoise.dark">
                        <strong>
                          <Trans i18nKey="labels.distance" />
                        </strong>
                      </Typography>
                    </Stack>
                    <Stack pl={1}>
                      <Typography variant="body2" color="text.grey.main">
                        {calculateDistanceKm(formik.values.distance)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Panel>
            </Stack>
          </Grid>
        </Grid>
        <ButtonForm
          comeBack={comeBack}
          buttonName={i18n.t("buttons.next")}
          formik={formik}
        />
      </form>
    </TransportOrderWizardTemplate>
  );
};

export default Schedule;
